import React from 'react';

function Footer({ language }) {
  const texts = {
    en: {
      companyName: "Binamandiri",
      copyright: "© 2024 Binamandiri. All rights reserved.",
      contactUs: "Contact Us",
      address: "1234 Street Name, City, Country",
      phone: "Phone: (123) 456-7890",
      email: "Email: info@binamandiri.com"
    },
    id: {
      companyName: "Binamandiri",
      copyright: "© 2024 Binamandiri. Hak cipta dilindungi undang-undang.",
      contactUs: "Hubungi Kami",
      address: "Jl. Nama Jalan 1234, Kota, Negara",
      phone: "Telepon: (123) 456-7890",
      email: "Email: info@binamandiri.com"
    }
  };

  return (
    <footer className="bg-gray-800 text-white py-8 px-4">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-2xl font-bold mb-4">{texts[language].companyName}</h2>
        <p className="mb-2">{texts[language].address}</p>
        <p className="mb-2">{texts[language].phone}</p>
        <p className="mb-4">{texts[language].email}</p>
        <p className="text-sm">{texts[language].copyright}</p>
      </div>
    </footer>
  );
}

export default Footer;
