import React from 'react';
import Slider from './Slider';

function Home() {
  return (
    <div>
      <Slider />
      <section className="p-8">
        <h1 className="text-3xl font-bold mb-4">Selamat Datang di Binamandiri</h1>
        <p className="text-lg text-gray-600">
          Ini adalah halaman utama. Silakan pilih menu navigasi untuk melihat halaman lainnya.
        </p>
      </section>
    </div>
  );
}

export default Home;
