import React, { useState } from 'react';
import { Link } from 'react-router-dom';  // Mengimpor Link dari react-router-dom
import Fade from 'react-reveal/Fade';

function Header({ language, setLanguage }) { // Menerima props language dan setLanguage
  const [isOpen, setIsOpen] = useState(false);

  const texts = {
    en: {
      home: 'Home',
      about: 'About Us',
      products: 'Properties',
      gallery: 'Gallery',
      contact: 'Contact',
    },
    id: {
      home: 'Beranda',
      about: 'Tentang Kami',
      products: 'Properti',
      gallery: 'Galeri',
      contact: 'Kontak',
    }
  };

  return (
    <header className="bg-white text-orange-500 p-4 flex flex-col md:flex-row md:justify-between items-center fixed top-0 left-0 w-full z-50 shadow-md">
      <div className="flex justify-between items-center w-full md:w-auto">
        <Fade top>
          <div className="text-lg font-bold">Binamandiri</div>
        </Fade>
        <div className="flex items-center md:hidden">
          {/* Menambahkan tombol bahasa di sebelah kiri tombol hamburger */}
          <button 
            onClick={() => setLanguage('id')} 
            className={`text-orange-500 px-2 ${language === 'id' ? 'font-bold' : ''}`}
          >
            ID
          </button>
          <button 
            onClick={() => setLanguage('en')} 
            className={`text-orange-500 px-2 ${language === 'en' ? 'font-bold' : ''}`}
          >
            EN
          </button>
          <button 
            onClick={() => setIsOpen(!isOpen)}
            className="text-orange-500 focus:outline-none"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
      </div>
      <nav className={`w-full ${isOpen ? 'block' : 'hidden'} md:flex md:items-center md:w-auto`}>
        <ul className="flex flex-col md:flex-row md:space-x-4">
          <li><Link to="/" className="block py-2 md:py-0 hover:text-gray-400">{texts[language].home}</Link></li>
          <li><Link to="/about" className="block py-2 md:py-0 hover:text-gray-400">{texts[language].about}</Link></li>
          <li><Link to="#products" className="block py-2 md:py-0 hover:text-gray-400">{texts[language].products}</Link></li>
          <li><Link to="#gallery" className="block py-2 md:py-0 hover:text-gray-400">{texts[language].gallery}</Link></li>
          <li><Link to="#contact" className="block py-2 md:py-0 hover:text-gray-400">{texts[language].contact}</Link></li>
          {/* Menambahkan tombol bahasa di navigasi untuk layar besar */}
          <li className="hidden md:block">
            <button 
              onClick={() => setLanguage('id')} 
              className={`block py-2 md:py-0 hover:text-gray-400 ${language === 'id' ? 'font-bold' : ''}`}
            >
              ID
            </button>
          </li>
          <li className="hidden md:block">
            <button 
              onClick={() => setLanguage('en')} 
              className={`block py-2 md:py-0 hover:text-gray-400 ${language === 'en' ? 'font-bold' : ''}`}
            >
              EN
            </button>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
