import React, { useState, useEffect, useCallback } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const slides = [
  { id: 1, title: "Slide 1", content: "Content for slide 1", img: "https://source.unsplash.com/random/800x600" },
  { id: 2, title: "Slide 2", content: "Content for slide 2", img: "https://source.unsplash.com/random/801x600" },
  { id: 3, title: "Slide 3", content: "Content for slide 3", img: "https://source.unsplash.com/random/802x600" }
];

function Slider() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = useCallback(() => {
    setCurrentSlide((currentSlide + 1) % slides.length);
  }, [currentSlide]);

  const prevSlide = useCallback(() => {
    setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);
  }, [currentSlide]);

  useEffect(() => {
    const slideInterval = setInterval(nextSlide, 5000); // Change slide every 5 seconds
    return () => clearInterval(slideInterval); // Clear interval on component unmount
  }, [nextSlide]);

  return (
    <section className="relative w-full h-screen overflow-hidden">
      <div className="flex w-full h-full transition-transform duration-1000" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
        {slides.map(slide => (
          <div key={slide.id} className="w-full h-full flex-shrink-0" style={{ backgroundImage: `url(${slide.img})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className="w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
              <div className="text-center text-white p-4">
                <h2 className="text-4xl font-bold mb-4">{slide.title}</h2>
                <p className="mb-8">{slide.content}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button onClick={prevSlide} className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-700 p-2 rounded-full text-white">
        <FaArrowLeft />
      </button>
      <button onClick={nextSlide} className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-700 p-2 rounded-full text-white">
        <FaArrowRight />
      </button>
    </section>
  );
}

export default Slider;
