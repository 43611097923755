// Mengimpor library React dan hook terkait
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'; 
// Mengimpor komponen Header, Home, About, dan Preloader dari folder components
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Preloader from './Preloader';
import Footer from './components/Footer';
// Mengimpor file CSS untuk styling komponen App
import './App.css';

// Komponen untuk menangani preloader saat berpindah rute
function RouteChangeHandler({ children }) {
  // State untuk mengelola status loading
  const [loading, setLoading] = useState(false);
  // Hook useLocation untuk mendeteksi perubahan rute
  const location = useLocation();

  useEffect(() => {
    // Set status loading menjadi true setiap kali rute berubah
    setLoading(true);
    // Timer untuk mengatur durasi preloader (1 detik)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Simulasi loading selama 1 detik setiap kali rute berubah

    // Membersihkan timer saat komponen unmount atau rute berubah
    return () => clearTimeout(timer);
  }, [location]); // Memicu useEffect setiap kali lokasi (rute) berubah

  // Render preloader jika loading, jika tidak render children (konten halaman)
  return (
    <>
      {loading && <Preloader />}
      {!loading && children}
    </>
  );
}

function App() {
  // State untuk mengelola preloader awal saat halaman pertama kali dimuat
  const [initialLoading, setInitialLoading] = useState(true);
  const [language, setLanguage] = useState('id'); // State untuk mengelola bahasa yang dipilih

  useEffect(() => {
    // Timer untuk mengatur durasi preloader awal (2 detik)
    const timer = setTimeout(() => {
      setInitialLoading(false);
    }, 2000); // Simulasi loading selama 2 detik

    // Membersihkan timer saat komponen unmount
    return () => clearTimeout(timer);
  }, []);

  // Menggunakan Router untuk mengatur navigasi dalam aplikasi
  return (
    <Router>
      <div className="App">
        {/* Tampilkan preloader awal saat initialLoading true */}
        {initialLoading && <Preloader />}
        {/* Tampilkan konten utama saat initialLoading false */}
        {!initialLoading && (
          <>
            {/* Komponen Header akan selalu ditampilkan */}
            <Header language={language} setLanguage={setLanguage} />
            {/* RouteChangeHandler untuk menangani preloader saat berpindah rute */}
            <RouteChangeHandler>
              <Routes> {/* Menggunakan Routes sebagai pengganti Switch */}
                {/* Rute untuk halaman About */}
                <Route path="/about" element={<About language={language} />} /> {/* Menggunakan element untuk merender komponen */}
                {/* Rute untuk halaman Home */}
                <Route path="/" element={<Home />} />
              </Routes>
            </RouteChangeHandler>
                {/* Komponen Footer akan selalu ditampilkan */}
                <Footer language={language} />
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
