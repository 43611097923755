import React from 'react';

function Preloader() {
  return (
    <div className="fixed inset-0 bg-white flex items-center justify-center z-50">
      <div className="wave">
        <div className="wave-bar"></div>
        <div className="wave-bar"></div>
        <div className="wave-bar"></div>
        <div className="wave-bar"></div>
        <div className="wave-bar"></div>
      </div>
      <style jsx>{`
        .wave {
          display: flex;
          align-items: center;
        }

        .wave-bar {
          background: #3498db;
          width: 5px;
          height: 50px;
          margin: 0 3px;
          animation: wave 1.2s infinite ease-in-out;
        }

        .wave-bar:nth-child(1) {
          animation-delay: -1.2s;
        }
        .wave-bar:nth-child(2) {
          animation-delay: -1.1s;
        }
        .wave-bar:nth-child(3) {
          animation-delay: -1s;
        }
        .wave-bar:nth-child(4) {
          animation-delay: -0.9s;
        }
        .wave-bar:nth-child(5) {
          animation-delay: -0.8s;
        }

        @keyframes wave {
          0%, 40%, 100% {
            transform: scaleY(0.4);
          }
          20% {
            transform: scaleY(1);
          }
        }
      `}</style>
    </div>
  );
}

export default Preloader;
