import React from 'react';
import Fade from 'react-reveal/Fade';

function About({ language }) {
  const texts = {
    en: {
      title: "About Us",
      description: "We are a company dedicated to providing the best services in property and real estate development. With years of experience, we continue to innovate to provide the best solutions for our clients.",
      visionTitle: "Our Vision",
      visionContent: "To be a leader in the property industry with innovation and excellent customer service.",
      missionTitle: "Our Mission",
      missionContent: "To provide innovative and high-quality property solutions that meet the needs and expectations of our clients.",
      valuesTitle: "Our Values",
      valuesContent: "Integrity, commitment, and excellence are the core values we uphold in every step we take.",
      teamTitle: "Our Team",
      teamContent: "Our team consists of experienced professionals dedicated to providing the best service and innovative solutions."
    },
    id: {
      title: "Tentang Kami",
      description: "Kami adalah perusahaan yang berdedikasi untuk memberikan layanan terbaik dalam properti dan pengembangan real estate. Dengan pengalaman bertahun-tahun, kami terus berinovasi untuk memberikan solusi terbaik bagi para klien kami.",
      visionTitle: "Visi Kami",
      visionContent: "Menjadi pemimpin dalam industri properti dengan inovasi dan layanan pelanggan yang unggul.",
      missionTitle: "Misi Kami",
      missionContent: "Memberikan solusi properti yang inovatif dan berkualitas tinggi yang memenuhi kebutuhan dan harapan klien kami.",
      valuesTitle: "Nilai Kami",
      valuesContent: "Integritas, komitmen, dan keunggulan adalah nilai utama yang kami pegang teguh dalam setiap langkah kami.",
      teamTitle: "Tim Kami",
      teamContent: "Tim kami terdiri dari profesional berpengalaman yang berdedikasi untuk memberikan layanan terbaik dan solusi inovatif."
    }
  };

  return (
    <section className="bg-gray-100 pt-24 pb-12 px-4 md:px-8">
      {/* Menambahkan padding-top untuk memberikan ruang antara header dan konten */}
      <div className="max-w-7xl mx-auto">
        <Fade left>
          <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-800 mb-8">
            {texts[language].title}
          </h2>
          <p className="text-lg text-gray-600 text-center mb-8">
            {texts[language].description}
          </p>
        </Fade>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <Fade left>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-bold mb-4">{texts[language].visionTitle}</h3>
              <p className="text-gray-600">
                {texts[language].visionContent}
              </p>
            </div>
          </Fade>
          <Fade right>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-bold mb-4">{texts[language].missionTitle}</h3>
              <p className="text-gray-600">
                {texts[language].missionContent}
              </p>
            </div>
          </Fade>
          <Fade left>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-bold mb-4">{texts[language].valuesTitle}</h3>
              <p className="text-gray-600">
                {texts[language].valuesContent}
              </p>
            </div>
          </Fade>
          <Fade right>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-bold mb-4">{texts[language].teamTitle}</h3>
              <p className="text-gray-600">
                {texts[language].teamContent}
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
}

export default About;
